/**
 * Participant Consent Form
 *
 * Description: Provides the elements
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-08
 * Notes: /
 */

import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, Modal } from 'react-bootstrap';
import { UserInformation } from '../auth_mgmt/auth_handling';

import CreatableSelect from 'react-select/creatable';

import Select from 'react-select';
import { Country, City } from 'country-state-city';

/**
 * Provide a modal for the consent form
 * Asks the user to agree to the terms and fill out the required fields
 * @param {*} param0 
 * @returns 
 */
export const ConsentFormModal = ({ show, handleClose, handleConsentSubmit }) => {

    const [agreed, setAgreed] = useState(false);
    const [participantName, setParticipantName] = useState('');
    const [researcherName, setResearcherName] = useState('');
    const [date, setDate] = useState('');

    const [role, setRole] = useState('');
    const [expertField, setExpertField] = useState('');
    const [experience, setExperience] = useState('');
    const [country, setCountry] = useState('');
    const [country_code, setCountryCode] = useState('');
    const [city, setCity] = useState('');
    const [yearOfBirth, setYearOfBirth] = useState('');

    const [error, setError] = useState('');

    const user = UserInformation();

    // Roles options
    const rolesOptions = [
        { value: 'Analyst', label: 'Analyst' },
        { value: 'Consultant', label: 'Consultant' },
        { value: 'Data Scientist', label: 'Data Scientist' },
        { value: 'Engineer', label: 'Engineer' },
        { value: 'Manager', label: 'Manager' },
        { value: 'Project Manager', label: 'Project Manager' },
        { value: 'Researcher', label: 'Researcher' },
        { value: 'Innovation Manager', label: 'Innovation Manager' },
        { value: 'Entrepreneur', label: 'Entrepreneur' },
    ];


    // Fields options
    const fieldsOptions = [
        { value: 'Autonomous Driving Expert', label: 'Autonomous Driving Expert' },
        { value: 'AI/ML Specialist', label: 'AI/ML Specialist' },
        { value: 'Automotive Engineering', label: 'Automotive Engineering' },
        { value: 'Business Analysis', label: 'Business Analysis' },
        { value: 'Digital Transformation', label: 'Digital Transformation' },
        { value: 'Economics', label: 'Economics' },
        { value: 'Human Factors', label: 'Human Factors' },
        { value: 'Transport Planning', label: 'Transport Planning' },
        { value: 'Urban Planning', label: 'Urban Planning' },
    ];


    const generateAnonymizedCode = () => {
        // Generate a random alphanumeric string of length 6
        const randomString = Math.random().toString(36).substring(2, 8).toUpperCase();
        // current date
        //const date = getCurrentDate();
        return `ip-${randomString}`;
    };

    // Set default values for participant name, researcher name, and date
    useEffect(() => {

        if (user) {
            setParticipantName(user.displayName);
        }

        setResearcherName('Marc Guerreiro Augusto');
        setDate(new Date().toISOString().split('T')[0]);

    }, [user]);

    const handleSubmit = (e) => {

        e.preventDefault();

        // Basic validation
        if (!agreed || !participantName || !date || !role || !expertField || !experience || !country || !city || !yearOfBirth) {
            setError('Please fill out all required fields and agree to the terms.');
            return;
        }

        // If validation passes, submit the consent form
        handleConsentSubmit({
            participantName,
            anonymous_id: generateAnonymizedCode(),
            date,
            researcherName,
            consentGiven: true,
            role,
            expertField,
            experience,
            country,
            city,
            yearOfBirth,
        });

        // Close modal after submitting consent
        handleClose();
    };

    // Fetch country options
    const countryOptions = Country.getAllCountries().map(country => ({
        value: country.isoCode,
        label: country.name,
        code: country.isoCode,
    }));

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Consent to Participate in Research Study</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form onSubmit={handleSubmit}>
                        {/* Research Overview */}
                        <div className="mb-4">
                            <h5>Title of Research</h5>
                            {/*<p align='right'><strong><i>A Platform Economy Model for Autonomous Mobility Solutions</i></strong></p>*/}
                            <p align='right' className='text-muted'>
                                <strong><i>
                                    Semantic Model Integration in Domain-Agnostic Use Case Engineering:<br />
                                    Developing a Platform Architecture for Autonomous Mobility Solutions                              
                                </i></strong>
                            </p>

                            <h5>Consent to Take Part in Research</h5>
                            <small>
                            <p>
                                I, the undersigned, voluntarily agree to participate in this research study. 
                                I understand that even if I agree to participate now, 
                                I can withdraw at any time or refuse to answer any question without any consequences of any kind. 
                                I understand that I can withdraw permission to use data from my interview within two weeks after the interview, 
                                in which case the material will be deleted.
                            </p>
                            <p>
                                I have had the purpose and nature of the study explained to me, and I have had the opportunity to ask questions about the study. 
                                I understand that participation involves an about 55 minutes mixed-methods research study of applying the UCM tool, 
                                followed by a brief survey and a semi-structured interview, 
                                during which I will share my experience regarding mobility, focusing on aspects such as automated driving use cases, platforms and platform economy. 
                                I understand that I will not directly benefit from participating in this research, and I agree to my interview answers being audio-recorded.
                            </p>
                            <p>
                                I understand that all information I provide for this study will be treated confidentially. 
                                In any report on the results of this research, my identity will remain anonymous. 
                                This will be done by changing my name and disguising any identifying details from my interview. 
                                Anonymized extracts from my interview may be quoted in this PhD thesis work, conference presentations, 
                                and published papers.
                            </p>
                            <p>
                                I acknowledge that, for the purpose of use case modeling, interview analysis, and transcription, 
                                external AI-based tools such as OpenAI’s ChatGPT and Whisper are applied.
                                This means certain anonymized information (e.g., interview transcripts, audio recordings) may be sent  
                                to these services for processing according to their respective privacy policies.
                                I understand that every effort will be made to remove personal identifiers before sharing data with these tools,
                                and that these external services have their own data handling and confidentiality standards.
                            </p>
                            <p>
                                I understand that signed consent forms and original audio recordings will be retained by the researcher until the exam board confirms the results of this work. 
                                A transcript of my interview with all identifying information removed will be retained for two years from the date of the exam board. 
                                Under freedom of information legislation, I am entitled to access the information I have provided at any time while it is in storage. 
                                I understand that I am free to contact the researcher to seek further clarification and information.
                            </p>
                            </small>
                        </div>

                        {/* Participant and Field of Expertise */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={2}><strong>Participant:</strong></Form.Label>
                            <Col md={4}>
                                <Form.Control
                                    type="text"
                                    value={participantName}
                                    onChange={(e) => setParticipantName(e.target.value)}
                                    placeholder="Full name"
                                    required
                                />
                            </Col>
                            <Form.Label column md={2}><strong>Experience:</strong></Form.Label>
                            <Col md={4}>
                                <Form.Control
                                    type="number"
                                    value={experience}
                                    onChange={(e) => setExperience(e.target.value)}
                                    placeholder="Years of experience"
                                    required
                                />
                            </Col>
                        </Form.Group>

                        {/* Year of Birth and Field */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={2}><strong>Year of Birth:</strong></Form.Label>
                            <Col md={4}>
                                <Form.Control
                                    as="select"
                                    value={yearOfBirth}
                                    onChange={(e) => setYearOfBirth(e.target.value)}
                                    required
                                >
                                    <option value="">Select Year</option>
                                    {Array.from({ length: 100 }, (_, i) => {
                                        const year = new Date().getFullYear() - i;
                                        return (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                            </Col>
                            
                            <Form.Label column md={2}><strong>Field:</strong></Form.Label>
                            <Col md={4}>
                                <CreatableSelect
                                    options={fieldsOptions}
                                    value={fieldsOptions.find(option => option.value === expertField)}
                                    onChange={(selectedOption) => setExpertField(selectedOption ? selectedOption.value : '')}
                                    placeholder="Select or type field"
                                    isClearable
                                    required
                                />
                            </Col>
                        </Form.Group>

                        {/* Country (City) and Role */}
                        <Form.Group as={Row} className="mb-3">

                            <Form.Label column md={2}><strong>Country:</strong></Form.Label>
                            <Col md={4}>
                                <Select
                                    options={countryOptions}
                                    value={countryOptions.find(option => option.value === country)}
                                    onChange={(selectedOption) => {
                                        setCountryCode(selectedOption ? selectedOption.value : '');
                                        setCountry(selectedOption ? selectedOption.label : '');
                                        setCity(''); // Reset city when the country changes
                                    }}
                                    placeholder="Select"
                                    isClearable
                                    required
                                />
                            </Col>

                            <Form.Label column md={2}><strong>Role:</strong></Form.Label>
                            <Col md={4}>
                                <CreatableSelect
                                    options={rolesOptions}
                                    value={rolesOptions.find(option => option.value === role)}
                                    onChange={(selectedOption) => setRole(selectedOption ? selectedOption.value : '')}
                                    placeholder="Select or type role"
                                    isClearable
                                    required
                                />
                            </Col>
                        </Form.Group>

                        {/* City */}
                        <Form.Group as={Row} className="mb-3">                         
                            {country && (
                                <>
                                <Form.Label column md={2}><strong>City:</strong></Form.Label>
                                <Col md={4}>
                                    <Select
                                        options={City.getCitiesOfCountry(country_code).map(city => ({
                                            value: city.name,
                                            label: city.name,
                                        }))}
                                        value={city ? { value: city, label: city } : null}
                                        onChange={(selectedOption) => setCity(selectedOption ? selectedOption.value : '')}
                                        placeholder="Select a city"
                                        isClearable
                                        isDisabled={!country} // Disable city dropdown if no country is selected
                                        required
                                    />
                                </Col>
                                </>
                            )}
                            <Col md={6}>
                                
                            </Col>
                        </Form.Group>

                        {/* Consent Checkbox */}
                        <Form.Group className="mb-4">
                            <Form.Check
                                type="checkbox"
                                id="consentCheckbox"
                                label={
                                    <label htmlFor="consentCheckbox" style={{ cursor: 'pointer' }}>
                                        I have read the consent form and voluntarily agree to participate in this research.
                                    </label>
                                }
                                checked={agreed}
                                onChange={(e) => setAgreed(e.target.checked)}
                                required
                            />
                        </Form.Group>

                        {/* Place and Date */}
                        <div align='right' style={ { marginBottom:'15px' } }>
                            <span><b>Berlin, {date}</b></span>
                        </div>

                        <hr />

                        {/* Researcher Name and Remark */}
                        <Row className="mb-3">
                            <Col md={6}>
                            <Form.Group as={Row} className="mb-3">
                                <p><strong>Researcher:</strong></p>
                                {/* phone number */}
                                <Form.Text className="text-muted">
                                    <i>For any questions or concerns, please contact the responsible researcher.</i>
                                    <br />
                                    <br />
                                    <i>Name: {researcherName}</i>
                                    <br />
                                    <i>Phone: +49 (0) 30 314 - 74038 </i>
                                    <br />
                                    <i>Email: marc.augusto@tu-berlin.de</i>
                                </Form.Text>
                            </Form.Group>
                            </Col>
                            <Col md={6}>
                                <p><strong>Remark:</strong></p>
                                <Form.Text className="text-muted">
                                    <i>
                                        The research project BeIntelli on Autonomous Mobility, funded by the 
                                        German Federal Ministry of Digital and Transportation, led by the TU Berlin, is the researcher's funding base and
                                        motivates this research. However, this work and content is carried out independently and is 
                                        not bound by any agreement.
                                    </i>
                                </Form.Text>
                            </Col>
                        </Row>

                        <hr />

                        {/* Error Alert */}
                        {error && <Alert variant="danger">{error}</Alert>}

                        {/* Submit Button */}
                        <div className="d-flex justify-content-center mt-4">
                            <Button variant="success" type="submit">
                                <i className="bi bi-check-circle" style={{ marginRight: '8px' }}></i>
                                Confirm Consent
                            </Button>
                        </div>

                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    );
};
