/**
 * Study Classification
 *
 * Description: Provides the elements
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-08
 * Notes: /
 */

import React from 'react';
import { Container, Row, Col, Tab, Nav, Card } from 'react-bootstrap';

 /**
 * Provides a classification of the study
 * @returns 
 */
export const Classification = () => {
    return (
      <Container fluid>
        <Row className="justify-content-center">
          <Col md={10}>
            <Tab.Container id="manual-tab" defaultActiveKey="objective">
              <Row>
                {/* Navigation Tabs */}
                <Col md={3} className="mb-3">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="objective" style={{ border: '1px solid', borderRadius: '5px', marginBottom: '10px' }}>
                        <i className="bi bi-info-circle"></i> Objective
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="task" style={{ border: '1px solid', borderRadius: '5px', marginBottom: '10px' }}>
                        <i className="bi bi-briefcase"></i> Your Task
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="outcome" style={{ border: '1px solid', borderRadius: '5px', marginBottom: '10px' }}>
                        <i className="bi bi-check2-circle"></i> Outcome
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="usage" style={{ border: '1px solid', borderRadius: '5px', marginBottom: '10px' }}>
                        <i className="bi bi-gear"></i> Usage
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
  
                {/* Tab Content */}
                <Col md={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="objective">
                      <Card className="shadow-sm">
                        <Card.Body>
                          <p>
                            The objective of this research is to introduce a <b>semantic representation</b> and a <b>Use Case Mapper tool</b> to identify similarities across use cases, 
                            enabling the creation of a <b>reference platform economy model</b> for AM Solutions (AMS), which helps stakeholders design, map, 
                            and optimize scalable and interoperable ecosystems.
                          </p>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
  
                    <Tab.Pane eventKey="task">
                      <Card className="shadow-sm">
                        <Card.Body>
                          <p>
                            In this study, you will <b>model use cases</b> related to AM using both the UCM Co-Pilot and manual mode. 
                            Also, you are asked to run through a quick <b>survey</b> and <b>answer a few questions</b> (structured interview).
                          </p>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
  
                    <Tab.Pane eventKey="outcome">
                      <Card className="shadow-sm">
                        <Card.Body>
                          <p>
                            A unified <b>semantic model</b> for AMS that serves as a <b>blueprint architecture</b>, 
                            alongside a knowledge-base-driven Use Case Mapper tool. 
                            This tool facilitates the design, mapping, and optimization of AMS ecosystems by <b>identifying 
                            recurrent requirements</b>, while suggesting solutions through an association guide based on <b>common patterns</b>.
                          </p>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
  
                    <Tab.Pane eventKey="usage">
                      <Card className="shadow-sm">
                        <Card.Body>
                          <p>
                            AMS <b>necessitate</b> the integration of hardware, software and ecosystem components. 
                            The <b>identification</b>, <b>choreography</b> and <b>development</b> of the elements, such as scenarios, actors, data, services 
                            in the AMS deployment landscape lacks thorough exploration.
                            <br /><br />
                            The developed semantic models and UCM tool form the foundation for <b>proposing a reference model</b>, 
                            enabling experts to model, analyze, and better understand the requirements for AM solution engineering.
                          </p>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    );
};
