/**
 * StudyProgressSidebar Component
 *
 * Description: This component is responsible for rendering the Study Progress Sidebar.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-03
 */

import React, { useState, useEffect } from 'react';
import { Button, ProgressBar, Card, Collapse, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const StudyProgressSidebar = ({ currentStep, setStudyStep, isExpanded, setIsExpanded }) => {

    const navigate = useNavigate();
    //const [isExpanded, setIsExpanded] = useState(true); // State to track if the sidebar is expanded or collapsed
    const [totalTime, setTotalTime] = useState(0); // Total time in seconds
    const [stepTime, setStepTime] = useState(0); // Step-specific time in seconds    
  
    // Total study duration in minutes
    const totalStudyDuration = 55;
    // Step time limits in minutes
    //const stepTimeLimits = [1, 15, 10, 5, 15]; // In minutes
    const [stepTimeLimits] = useState([10, 15, 10, 5, 15]);
  
    // Toggle the sidebar's collapsed state
    const toggleSidebar = () => {
      setIsExpanded(!isExpanded);
    };
  
    // Navigation function to go to a specific step
    /*
    const handleNavigate = (step) => {

        //console.log('Current Step:', step);

        switch (step) {
            case 1:
                // do nothing (already in the first step)
                break;
            case 2:
                console.log('Navigate to Custom Use Case');
                //navigate('/create', { state: 'manually'} ); // start custom use case, manual mode
                break;
            case 3:
                console.log('Navigate to Co-Pilot Revisit');
                //navigate('/Create', { state: 'copilot' }); // start co-pilot revisit
                break;
            case 4:
                // interview
                break;
            case 5:
                // survey
                break;
            default:
                break;
        }
    };
    */
  
    // Steps Overview
    const steps = [
      { label: 'SP Modeling', step: 1 },
      { label: 'Custom Use Case', step: 2 },
      { label: 'Co-Pilot Revisit', step: 3 },
      { label: 'Survey', step: 4 },
      { label: 'Interview', step: 5 },      
    ];
  
    // Timer effect
    useEffect(() => {
      const timer = setInterval(() => {
        setTotalTime((prevTime) => prevTime + 1);
        setStepTime((prevTime) => prevTime + 1);
      }, 1000);
  
      return () => clearInterval(timer);
    }, []);
  
    // Reset step time when the current step changes
    useEffect(() => {
        setStepTime(0);
        
        switch (currentStep) {
            case 1:
                // do nothing (already in the first step)
                break;
            case 2:
                console.log('Navigate to Custom Use Case');
                navigate('/Create', { state: { mode: 'manually' } }); // start custom use case, manual mode
                break;
            case 3:
                console.log('Navigate to Co-Pilot Revisit');
                navigate('/Create', { state: { mode: 'copilot' } }); // start co-pilot revisit
                break;
            case 4:
                // survey
                console.log('Navigate to Survey');
                navigate ('/Create', { state: { mode: 'survey' } });
                break;
            case 5:
                // interview
                console.log('Navigate to Interview');
                navigate ('/Create', { state: { mode: 'interview' } });
                break;
            default:
                break;
        }

    }, [currentStep, navigate]);
  
    // Format time in MM:SS format
    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    // update study step when time is over
    /*
    useEffect(() => {

        const stepTimeLimits = [10, 15, 10, 15, 5]; // In minutes
        if (stepTime >= stepTimeLimits[currentStep - 1] * 60) {
            if (currentStep > 5) {
                // study time is over, but participant can still complete the study
                return;
            }
            setStudyStep(currentStep + 1);
            // reset step time
            setStepTime(0);
        }

    }, [stepTime, currentStep, setStudyStep]);
    */

    // update study step when time is over
    useEffect(() => {

      //let stepTimeLimits = stepTimeLimits; // [10, 15, 10, 15, 5]; // In minutes
      if (currentStep <= 5 && stepTime >= stepTimeLimits[currentStep - 1] * 60) {
          if (currentStep === 5) {
              // If it's the last step, do not advance but allow time to continue
              return;
          }
          //setStudyStep(currentStep + 1);
          // reset step time
          //setStepTime(0);
      }

    }, [stepTime, currentStep, setStudyStep, stepTimeLimits]);
  
    return (  
      <>
        <div
          style={{
            position: 'fixed',
            top: '120px',
            left: isExpanded ? '20px' : '10px',
            width: isExpanded ? '250px' : '100px',
            backgroundColor: 'white',
            zIndex: 1000,
            padding: isExpanded ? '15px' : '5px',
            borderRadius: '10px',
            boxShadow: '0 0 15px rgba(0,0,0,0.2)',
            transition: 'width 0.3s ease, padding 0.3s ease',
          }}
        >        
          <div className="d-flex justify-content-between align-items-center mb-2">
            <Card.Title style={{ fontSize: isExpanded ? '1.2rem' : '0.8rem', margin: 0 }}>
              {isExpanded ? 'Study Progress' : 'Progress'}
            </Card.Title>
            <Button
              variant="light"
              size="sm"
              onClick={toggleSidebar}
              style={{ border: 'none', boxShadow: 'none' }}
            >
              {isExpanded ? (
                <i className="bi bi-chevron-left"></i>
              ) : (
                <i className="bi bi-chevron-right"></i>
              )}
            </Button>
          </div>
  
          <Collapse in={isExpanded}>
            <div>
              {/* Meta Progress Bar */}
              <Card className="mb-3">
                <Card.Body>
                  <div style={{ position: 'relative', width: '100%' }}>
                    <ProgressBar
                      now={(totalTime / (totalStudyDuration * 60)) * 100}
                      //label={`${Math.floor(totalTime / 60)} min / ${totalStudyDuration} min`}
                      style={{ 
                          height: '20px', 
                          borderRadius: '5px'
                      }}
                      variant="success"
                    />
                    <span
                      style={{
                        position: 'absolute',
                        right: '10px', // Align to the right side
                        top: '50%',
                        transform: 'translateY(-50%)', // Vertically center the text
                        color: 'lightgray', // Set the label color to gray
                        fontWeight: 'bold',
                        fontSize: '0.8rem',
                      }}
                    >
                      {`${Math.floor(totalTime / 60)} min / ${totalStudyDuration} min`}
                    </span>
                  </div>
                  <div className="mt-1" align='right'>
                    <small><strong>Elapsed Time:</strong> {formatTime(totalTime)}</small>
                  </div>
                </Card.Body>
              </Card>
  
              {/* Step Progress Bar */}
              <Card className="mb-3">
                <Card.Body>
                  <div style={{ position: 'relative', width: '100%' }}>
                  <ProgressBar
                    now={(stepTime / (stepTimeLimits[currentStep - 1] * 60)) * 100}
                    //label={`Step ${currentStep} - ${formatTime(stepTime)}`}
                    style={{ height: '20px', borderRadius: '5px' }}
                    variant="success"
                  />
                  <span
                    style={{
                      position: 'absolute',
                      right: '10px', // Align to the right side
                      top: '50%',
                      transform: 'translateY(-50%)', // Vertically center the text
                      color: 'lightgray', // Set the label color to gray
                      fontWeight: 'bold',
                      fontSize: '0.8rem',
                    }}
                  >
                    {`Step ${currentStep} - ${formatTime(stepTime)}`}
                  </span>
                  </div>

                  <div className="mt-1" align='right'>
                    <small><strong>Task {currentStep}, Time Limit:</strong> {stepTimeLimits[currentStep - 1]} min</small>
                  </div>
                </Card.Body>
              </Card>
  
              {/* Steps List */}
              <div>
                {steps.map((step) => (
                    <Row key={`step-${step.step}`}>
                        <Col sm={1} className='mb-2'>
                            <small>{step.step}</small>
                        </Col>
                        <Col sm={7} className='mb-2'>
                            <Button
                                key={step.step}
                                variant={
                                    currentStep === step.step ? 'warning' : 'outline-secondary'
                                    &&
                                    currentStep > step.step && 'success'
                                }
                                className="w-100 btn-sm"
                                //onClick={() => handleNavigate(step.step)}
                                disabled
                                /*
                                ={
                                    currentStep === 1 || step.step === 1 ||
                                    currentStep < step.step
                                }
                                */
                                style={{
                                fontSize: '11px', //'0.9rem',
                                transition: 'background-color 0.3s ease',
                                }}
                            >
                                {step.label}
                            </Button>
                        </Col>
                        <Col sm={3} className='mb-2'>
                          <Button
                            variant={
                                currentStep > step.step ? 'success' : 'outline-secondary'
                            }
                            className="w-100 btn-sm"
                            style={{
                                fontSize: '11px',
                                transition: 'background-color 0.3s ease',
                            }}
                            disabled
                            //disabled={currentStep < step.step}
                            onClick={() => setStudyStep(step.step)}
                          >
                              {/* Icon logic */}
                              {currentStep === step.step && <i className="bi bi-hourglass-split"></i>}
                              {currentStep > step.step && <i className="bi bi-check"></i>}
                              {currentStep < step.step && currentStep !== step.step && <i className="bi bi-lock"></i>}
                          </Button>
                        </Col>
                    </Row>
                ))}
                    
              </div>
                          
            </div>
          </Collapse>
        </div>

      </>
    );
};
  

export default StudyProgressSidebar;
